.Proxy {
    height: 100vh;
    .proxy-group {
        height: 231px;
        margin: 0 auto;
        width: 733px;
        display: flex;
        flex-direction: row;
        text-align:center;
        .proxy-items {
            width: 50%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #FAFAFA;
        }
        .items1 {
            background: url(../assets/proxy/bj1.png) no-repeat;
            background-size: 371px 231px;
        }
        .items2 {
            background: url(../assets/proxy/bj2.png) no-repeat;
            background-size: 371px 231px;
        }
        p {
            margin: 19px 0;
            font-size: 28px;
        }
        span {
            margin: 19px 0;
            font-size: 38px;
        }
    }
    .tables {
        max-height: calc(100% - 231px - 88px - 22px - 20px);
        width: 702px;
        padding: 14px 0;
        box-sizing: border-box;
        margin: 0 auto;
        background: #fff;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        .tables-header {
            display: inline-flex;
            align-items: center;
            height: 85px;
            // line-height: 85px;
            font-size: 22px;
            color: #000;
        }
        .tables-body {
            min-height: 60px;
            font-size: 22px;
            color: #000;
            opacity: .6;
        }

        .tables-body span {
            display: inline-flex;
            align-items: flex-start;
            margin-bottom: 20px;
        }
        .tables-header span {
            display: inline-block;
        }
        .tables-header span, .tables-body span{
            word-break: break-all;
            box-sizing: border-box;
           
            &:first-of-type {
                padding-left: 20px;
                width: 220px;
            }
            &:nth-of-type(2) {
                width: 50px;
                text-align: center;
                justify-content: center;
            }
            &:nth-of-type(3) {
                width: 167px;
                text-align: center;
                justify-content: center;
            }
            &:nth-of-type(4) {
                width: 100px;
                text-align: center;
                justify-content: center;
            }
            &:nth-of-type(5) {
                text-align: right;
                justify-content: flex-end;
                padding-right: 20px;
                width: 165px;
            }
        }
        .lists {
            max-height: calc(100vh - 231px - 88px - 22px - 20px - 85px - 15px);
            overflow: auto;
        }
    }
}