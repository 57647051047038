.Invite {
    height: 100vh;
    .invite-num {
        width: 100%;
        max-width:733px;
        height: 231px;
        /*margin: 20px 8px 2px;
        padding: 0 0 0 39px;*/
        background: url(../assets/bj5.png) no-repeat;
        background-size: 100% 100%;
        color: #FAFAFA;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin:0 auto;
        p {
            margin: 19px 0;
            font-size: 28px;
        }
        span {
            margin: 19px 0;
            font-size: 38px;
        }
    }
    .tables {
        max-height: calc(100% - 231px - 88px - 22px - 20px);
        width: 702px;
        padding: 14px 0;
        box-sizing: border-box;
        margin: 0 auto;
        background: #fff;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        .tables-header {
            height: 85px;
            line-height: 85px;
            font-size: 22px;
            color: #000;
        }
        .tables-body {
            min-height: 60px;
            font-size: 22px;
            color: #000;
            opacity: .6;
        }

        .tables-body span {
            display: inline-flex;
            align-items: flex-start;
            margin-bottom: 20px;
        }
        .tables-header span {
            display: inline-block;
        }
        .tables-header span, .tables-body span{
            word-break: break-all;
            box-sizing: border-box;
            &:first-of-type {
                padding-left: 20px;
                width: 98px;
            }
            &:nth-of-type(2) {
                width: 114px;
            }
            &:nth-of-type(3) {
                width: 141px;
            }
            &:nth-of-type(4) {
                width: 124px;
                text-align: center;
                justify-content: center;
            }
            &:nth-of-type(5) {
                text-align: center;
                justify-content: center;
                width: 78px;
            }
            &:nth-of-type(6) {
                text-align: right;
                padding-right: 20px;
                width: 147px;
            }
        }
        .lists {
            max-height: calc(100vh - 231px - 88px - 22px - 20px - 85px - 15px);
            overflow: auto;
        }
    }
}