.App, body {
  height: 100vh;
  .adm-mask{
    transform: scale(0.5);
    svg{
      transform: scale(0.75);
    }
  }
}
html,body{
  font-size: 16px !important;
}

:root{
  --dark-blue:#00458b;
}

.bg-dark-blue{
  background-color: var(--dark-blue);
}

.text-dark-blue{
  color: var(--dark-blue);
}

.border-dark-blue{
  border-color: var(--dark-blue) !important;
}