#header {
    height: 100vh;
}
.mobile-nav-button{
    position: absolute;
    top: 10px;
    right: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size:16px;
    z-index: 10;
    border:none;
    border-radius:4px;
}
.lang-menu{
    text-align: center;

}
.lang-btn{
    background-color: #092947;
    border: 1px solid white;
    color:white;
}
// .headers {
//     margin: 0 26px;
//     height: 88px;
//     position: relative;
//     .back {
//         position: absolute;
//         left: 0;
//         top: 0;
//         width: 60px;
//         height: 100%;
//         display: flex;
//         align-items: center;
//         img {
//             height: 40px;
//         }
//     }
//     h3 {
//         font-size: 20px;
//         color: #292A2A;
//         text-align: center;
//         margin: 0;
//         line-height: 88px;
//     }
// }
// .isHome {
//     font-size: 20px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     .out-img {
//         width: 20px;
//         height: 20px;
//         margin-right: 3px;
//         vertical-align: sub;
//     }
// }
// .user-info{
//     margin:0 26px;
//     font-size:16px;
// }