.Index {
    width: 100%;
    height: 100%;
    background: url(../assets/bj.png) no-repeat top right;
    background-size: 655px 540px;
    h1 {
      color: #2D3038;
      font-size: 36px;
      margin: 0;
      text-align: center;
    }
    .group-input {
      margin: 0 48px;
      display: flex;
      flex-direction: column;
      margin-top: 41px;
     /* &:first-of-type {
        margin-top: 185px;
      }*/
      span {
        font-size: 20px;
      }
      input {
        height: 65px;
        margin-top: 24px;
        border: none;
        background: transparent;
        border-bottom: 2px solid #E8E8E8;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-size: 18px;
        }
      }
    }
    .warn {
      font-size: 18px;
      text-align: center;
      color: #323232;
      margin: 37px 50px 14px 0;
      span {
        color: #0090EF;
      }
    }
    .button-group {
      width: 100vw;
      display: flex;
      justify-content: center;
    }
    .adm-button-primary {
      width: 80%;
      max-width:60%;
      height: 100%;
      line-height: 30px;
      font-size: 24px;
      background: #343842;
      border:2px solid  #343842;
      border-radius: 15px;
    }
  }