.task {
    height: 100vh;
    .tables {
        max-height: calc(100% - 88px - 20px);
        width: 702px;
        padding: 14px 0;
        box-sizing: border-box;
        margin: 0 auto;
        background: #fff;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        h3 {
            margin: 0;
            font-size: 28px;
            padding-top: 14px;
            padding-left: 20px;
        }
        .tables-header {
            height: 85px;
            line-height: 85px;
            font-size: 22px;
            color: #000;
        }
        .tables-body {
            min-height: 60px;
            font-size: 22px;
            color: #000;
            opacity: .6;
        }

        .tables-body span {
            display: inline-flex;
            align-items: flex-start;
            margin-bottom: 20px;
        }
        .tables-header span {
            display: inline-block;
        }
        .tables-header span, .tables-body span{
            word-break: break-all;
            box-sizing: border-box;
            &:first-of-type {
                padding-left: 20px;
                width: 149px;
            }
            &:nth-of-type(2) {
                width: 180px;
                text-align: center;
                justify-content: center;
            }
            &:nth-of-type(3) {
                width: 180px;
                text-align: center;
                justify-content: center;
            }
            &:nth-of-type(4) {
                text-align: right;
                justify-content: flex-end;
                padding-right: 20px;
                width: 193px;
            }
            &.del-font {
                opacity: .3;
            }
            .del-btn {
                display: block;
                padding: 0 23px;
                height: 37px;
                line-height: 37px;
                background: linear-gradient(147deg, #454545, #23272A);
                border-radius: 19px;
                color: rgba($color: #fff, $alpha: 0.6);
                font-style: normal;
            }
        }
        .lists {
            max-height: calc(100vh - 122px - 85px - 54px);
            overflow: auto;
        }
    }
}